import { TitleMedium } from "../Title";
import Button from "../Button";
import React, { useState } from "react";
import './index.scss'

function CheckBox({ label, ...props }) {

   return (
      <>
         <label className="container-checkbox CheckBox">
            <span>{label}</span>
            <input type="checkbox" {...props} />
            <span className="checkmark" />
         </label>
      </>
   )
}

let checkboxes =  [
	{label: 'eAD', name: 'group[4129][1]'},
	{label: '2214', name: 'group[4129][2]'},
	{label: 'PROXY', name: 'group[4129][4]'},
];

function NewsLetterForm() {
   let [checkBoxIndexes, setCheckBoxIndexes] = useState([0]);

   return (
      <form className="NewsLetterForm"
            action={`https://envelopead.us12.list-manage.com/subscribe/post?u=19fd5f34ff98bc18d991bed35&amp;id=b117582683`}
            method="post"
            onSubmit={e => {
               let [FNAME, ...LNAME] = e.target.FNAME.value.split(' ');

               e.target.FNAME.value = FNAME;
               e.target.LNAME.value = LNAME.join(' ');
            }}
      >
         <TitleMedium>
            Sign up for our
            newsletter
         </TitleMedium>

         <input type="email" placeholder="E-mail Address" name="EMAIL"/>
         <input type="text" placeholder="FULL NAME" name="FNAME"/>
         <input type="hidden" name="LNAME"/>

         {
            checkboxes.map((checkbox, index) => {
				let isChecked = checkBoxIndexes.includes(index);
				let MIN_LENGTH = 1;

				return (
					<CheckBox
						checked={isChecked}
						onChange={() => {
							if (!isChecked) {
								return setCheckBoxIndexes([...checkBoxIndexes, index])
							}

							if (checkBoxIndexes.length <= MIN_LENGTH) {
								return false
							}

							setCheckBoxIndexes(checkBoxIndexes.filter(id => id !== index));
						}}
						label={checkbox.label}
                        name={checkbox.name}
						key={checkbox.label} />
				)
            })
         }

         <Button type='submit'
            className="Subscribe">
            Subscribe
         </Button>
      </form>
   )
}


export default NewsLetterForm;
