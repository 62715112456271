import React from "react"
import Img from 'gatsby-image'
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO"
import "./index.scss"
import {TitleMedium} from "../../../components/Title"
import Footer from "../../../components/Footer";
import {graphql} from "gatsby";
import NewsLetterForm from "../../../components/NewsLetterForm";

function getDomainLink(href) {
   let link = href.split('//')[1];

   link = link || '';

   return link.replace('www.', '').replace(/[/.].*/, '') || href;
}

function mapLinks (links) {
   let socials = typeof links === 'string' ? links.split(',') : [];

  return socials
     .filter(data => data)
     .map(link => ({
        name: getDomainLink(link),
        href: link
     }));
}

function mapContactData(contacts) {
   return contacts.map(data => {

      data.socials = mapLinks(data.social);

      return data;
   });
}

function CardInfo({title, desc, email, site, socials = []}) {

   return (
      <div className="CardInfo">
         <TitleMedium>{title} </TitleMedium>
         <p className="desc"> {desc} </p>

         {
            email &&
            <>
               <p className="link-title"> Email </p>
               <a href={`mailto:${email}`} className="contact-link">{email}</a>
            </>
         }

         {
            site &&
            <>
               <p className="link-title"> website </p>
               <a href={site} target={site[0] === '/' ? "_self" : "_blank"} className="contact-link">{site}</a>
            </>
         }

         {
            socials.length > 0 &&
            <>
               <p className="link-title"> social </p>
               {socials.map(social => (
                  <a href={social.href}
                     target={social.href[0] === '/' ? "_self" : "_blank"}
                     className="contact-link"
                     key={social.name}>
                     {social.name}
                  </a>))
               }
            </>
         }
      </div>
   )
}




let topCardInfo = {
   title: "Envelope Architecture + Design",
   desc: "2212 Sixth Street Berkeley, CA 94710 USA 510.644.2400",
   email: "INFO@ENVELOPEAD.COM",
   site: null,
   social: 'https://www.facebook.com/envelopead, https://www.instagram.com/envelopead',
};

const StudioContact = ({data}) => {
   let {cms: {contacts}, hero} = data;

    return (
      <Layout Footer={<Footer/>}>
         <SEO title="Contact" keywords={[`gatsby`, `application`, `react`]}/>

         <div className="contact-content">

            <div className="top-contact-info">
               <CardInfo {...topCardInfo} socials={mapLinks(topCardInfo.social)} />
               <NewsLetterForm/>
               <Img fixed={hero.childImageSharp.fluid} alt={"Contact info"}/>
            </div>

            <div className="contact-cards">
               {
                  mapContactData(contacts).map((card, index) => <CardInfo {...card} key={index.toString()}/>)
               }
            </div>
         </div>
      </Layout>
   )
};

export const query = graphql`
{
  cms {
     contacts {
       title
       desc: description
       site
       social
     }
   }
   
   hero: file(relativePath: { eq: "Envelope_Studio_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
}
`;

export default StudioContact
